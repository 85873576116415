import { Injectable } from '@angular/core';
import { PharHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { Observable } from 'rxjs';
import { AssignModel } from './assign.model';
import { IAssignComment } from './assign-comments/assign-comment.interface';

@Injectable()
export class AssignService {
  constructor(
    private http: PharHttp,
    private config: AppConfig,
  ) {}

  getAssignsByProject(projectId: number): Observable<any> {
    return this.http.get(this.config.buildUri('assign') + '/AssignedPerProject?projectId=' + projectId);
  }

  getAssign(id: number): Observable<any> {
    return this.http.get(this.config.buildUri('assign') + '/SingleAssignment?assignmentId=' + id);
  }

  deleteAssign(id: number, deleteGroup: boolean): Observable<any> {
    let url = this.config.buildUri('assign') + '/AssignedForm?id=' + id;
    if (deleteGroup) {
      url = this.config.buildUri('assign') + '/AssignedForm?id=' + id + '&deleteGroup=' + deleteGroup;
    }

    return this.http.delete(url);
  }

  assignToUsers(data): Observable<any> {
    delete data.id;
    return this.http.post(this.config.buildUri('assign') + '/UserAssignment', data);
  }

  assignToGroup(data): Observable<any> {
    delete data.id;
    return this.http.post(this.config.buildUri('assign') + '/SecurityGroupAssignment', data);
  }

  assignToRole(data): Observable<any> {
    return this.http.post(this.config.buildUri('assign') + '/AssignFormToRole', data);
  }

  assignToEmails(data): Observable<any> {
    return this.http.post(this.config.buildUri('assign') + '/AddFormToEmailOfUser', data);
  }

  createAssignment(assignment: AssignModel): Observable<AssignModel> {
    return this.http.post(this.config.buildUri('assign') + '/CreateAssignment?isAmend=false', assignment);
  }

  createAssignments(assignments: AssignModel[]): Observable<AssignModel[]> {
    return this.http.post(this.config.buildUri('assign') + '/CreateAssignments?isAmend=false', assignments);
  }

  getCommentsByAssignId(assignmentId: number): Observable<IAssignComment[]> {
    return this.http.get(
      this.config.buildUri('assessmentComments') + '/AssessmentComments' + '?assignmentId=' + assignmentId,
    );
  }

  updateComment(data: Partial<IAssignComment>): Observable<IAssignComment> {
    return this.http.put(this.config.buildUri('assessmentComments') + '/AssessmentComments', data);
  }

  createComment(data: Partial<IAssignComment>): Observable<IAssignComment> {
    return this.http.post(this.config.buildUri('assessmentComments') + '/CreateAssessmentComments', data);
  }

  deleteComment(commentId: number): Observable<IAssignComment> {
    return this.http.delete(
      this.config.buildUri('assessmentComments') + '/AssessmentComments' + '?assessmentCommentsId=' + commentId,
    );
  }

  resolveComment(commentId: number): Observable<IAssignComment> {
    return this.http.post(
      this.config.buildUri('assessmentComments') + '/MarkCommentAsResolved' + '?assessmentCommentId=' + commentId,
      {},
    );
  }

  getCommentsCounter(projectId: number) {
    return this.http.get(
      this.config.buildUri('assessmentComments') + '/AssessmentCommentCount' + '?projectId=' + projectId,
    );
  }

  checkAssignmentsCommentsStatus(assignmentsIds: number[]): Observable<{ canChangeStatuses: boolean }> {
    return this.http.post(this.config.buildUri('assessmentComments') + '/CheckAllResolved', assignmentsIds);
  }

  updateAssignments(assignments: AssignModel[]): Observable<AssignModel[]> {
    assignments = assignments.map(assessment => ({
      ...assessment,
      formId: assessment.form?.id ?? assessment.formId,
      formVersionId: assessment.form?.id ?? assessment.formId,
    }));

    return this.http.put(this.config.buildUri('assign') + '/UserAssignments', assignments);
  }
}
