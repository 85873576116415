import { SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../core/utils.service';

@Pipe({
  name: 'pharTransformTimeUnit',
  standalone: true,
})
export class TimeUnitConverterPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(value: string | number, unit: number, prefix?: string, suffix?: string, fullUnitName?: boolean): SafeHtml {
    return `${prefix || ''}${value ?? ''}${this.utilsService.unitToName(unit, fullUnitName)}${suffix || ''}`;
  }
}
