import { Pipe, PipeTransform } from '@angular/core';
import { Statuses } from '../models/statuses.enum';
import { EntityStates, StudyEntityActionStateService } from '../services/study-entity-action-state.service';

@Pipe({
  name: 'entityActionState',
  standalone: true,
  pure: true,
})
export class StudyEntityActionStatePipe implements PipeTransform {
  constructor(private studyEntityActionStateService: StudyEntityActionStateService) {}

  transform(entityStatus: Statuses, state: EntityStates, studyStatus: Statuses, nextStatus: Statuses): boolean {
    return this.studyEntityActionStateService.getEntityActionState(entityStatus, state, studyStatus, nextStatus);
  }
}
