import { Injectable } from '@angular/core';
import { Statuses } from '../models/statuses.enum';

export type EntityStates =
  | 'deleteLocked'
  | 'changeStatusLocked'
  | 'replaceLocked'
  | 'commentsLocked'
  | 'updateLocked'
  | 'revertLocked'
  | 'changeStatusChecked';

@Injectable({ providedIn: 'root' })
export class StudyEntityActionStateService {
  getEntityActionState(
    entityStatus: Statuses,
    state: EntityStates,
    studyStatus: Statuses,
    nextStatus: Statuses,
  ): boolean {
    if (!entityStatus) {
      return false;
    }

    switch (state) {
      case 'deleteLocked':
        return this.isDeleteLocked(entityStatus, studyStatus, nextStatus);
      case 'changeStatusLocked':
        return this.isChangeStatusLocked(entityStatus, studyStatus, nextStatus);
      case 'commentsLocked':
        return this.isCommentsLocked();
      case 'replaceLocked':
        return this.isReplaceLocked(entityStatus, studyStatus, nextStatus);
      case 'updateLocked':
        return this.isUpdateLocked(entityStatus, studyStatus, nextStatus);
      case 'revertLocked':
        return this.isRevertLocked();
      case 'changeStatusChecked':
        return this.isEntityChangeStatusChecked(entityStatus, nextStatus);
      default:
        return false;
    }
  }

  isBulkStatusChangeLocked(entityStatuses: Statuses[], studyStatus: Statuses, nextStatus: Statuses): boolean {
    const studyStatusChangeLocked =
      studyStatus !== Statuses.Draft &&
      studyStatus !== Statuses.UnderReview &&
      studyStatus !== Statuses.RevisionCompleted;

    return (
      studyStatusChangeLocked ||
      this.isBulkStatusChangeChecked(entityStatuses, nextStatus) ||
      //we don't have endpoint to delete multiple entities, so "confirm all" is disabled
      entityStatuses.some(entityStatus => entityStatus === Statuses.PendingDeletion)
    );
  }

  isBulkStatusChangeChecked(entityStatuses: Statuses[], nextStatus: Statuses): boolean {
    return entityStatuses.every(entityStatus => this.isEntityChangeStatusChecked(entityStatus, nextStatus));
  }

  /**
   *
   * @param entityStatus is the current one
   * @param nextStatus is the next one
   */
  isEntityChangeStatusChecked(entityStatus: Statuses, nextStatus: Statuses): boolean {
    return entityStatus === nextStatus || entityStatus === Statuses.Approved || entityStatus === Statuses.Active;
  }

  private isDeleteLocked(entityStatus: Statuses, studyStatus: Statuses, nextStatus: Statuses): boolean {
    switch (studyStatus) {
      case Statuses.Draft:
        return entityStatus === Statuses.Approved || entityStatus === nextStatus;
      case Statuses.RevisionInProgress:
        return entityStatus !== Statuses.Active && entityStatus !== Statuses.New;
      default:
        return true;
    }
  }

  private isChangeStatusLocked(entityStatus: Statuses, studyStatus: Statuses, nextStatus: Statuses): boolean {
    switch (studyStatus) {
      case Statuses.Draft:
      case Statuses.UnderReview:
        return entityStatus === Statuses.Approved || entityStatus === nextStatus;
      case Statuses.RevisionCompleted:
        return entityStatus === Statuses.Active || entityStatus === Statuses.Approved;
      default:
        return true;
    }
  }

  private isCommentsLocked(): boolean {
    return false;
  }

  private isReplaceLocked(entityStatus: Statuses, studyStatus: Statuses, nextStatus: Statuses): boolean {
    switch (studyStatus) {
      case Statuses.Draft:
        return entityStatus === Statuses.Approved || entityStatus === nextStatus;
      case Statuses.RevisionInProgress:
        return entityStatus === Statuses.Approved;
      default:
        return true;
    }
  }

  private isUpdateLocked(entityStatus: Statuses, studyStatus: Statuses, nextStatus: Statuses): boolean {
    switch (studyStatus) {
      case Statuses.Draft:
        return entityStatus === Statuses.Approved || entityStatus === nextStatus;
      case Statuses.RevisionInProgress:
        return entityStatus === Statuses.Approved;
      default:
        return true;
    }
  }

  private isRevertLocked(): boolean {
    return true;
  }
}
