import { inject, Pipe, PipeTransform } from '@angular/core';
import { EventModel } from '../event.model';
import { UtilsService } from '../../core/utils.service';

@Pipe({
  name: 'pharRecurringEventTranslate',
  standalone: true,
})
export class RecurringEventTranslatePipe implements PipeTransform {
  utilsService: UtilsService = inject(UtilsService);

  transform(event: EventModel): any {
    let text = '';
    if (!event.isRepeatable) {
      return text;
    }
    text += `Repeat every: ${event.repeatEvery} ${this.utilsService.unitToName(event.repeatEveryUnit)} \nEnds after: ${event.endsAfter} ${this.utilsService.unitToName(event.endsAfterUnit)}`;
    return text;
  }
}
